export enum RoutePath {
  BasePath = '',
  OverviewPath = 'overview',
  HardnessConverterPath = 'hardness-converter',
  AQMCalculatorPath = 'aqm-calculator',
  InsulationSolutionsPath = 'insulation-solutions',
  MaterialPropertiesEstimator = 'material-properties-estimator',
  MaterialsSupplierDatabasePath = 'materials-supplier-database',
  LearnMorePath = 'learn-more',
  ForbiddenPath = 'forbidden',
}

export enum RouteNames {
  Base = 'Materials App Center',
  Overview = 'Overview',
  HardnessConverter = 'Hardness Converter',
  AQMCalculator = 'AQM Calculator',
  InsulationSolutions = 'Insulation Solutions',
  MaterialsSupplierDatabase = 'Materials Supplier Database',
  MaterialPropertiesEstimator = 'Material Properties Estimator',
  LearnMore = 'Learn More',
  Forbidden = 'forbidden',
}
